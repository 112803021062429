import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-icon-button',
  styleUrl: 'x-icon-button.sass',
})
export class XIconButton {
  @Prop() href: string;

  render() {
    return (
      <Host>
        {!!this.href ? (
          <a href={this.href}>
            <slot></slot>
          </a>
        ) : (
          <button type="button">
            <slot></slot>
          </button>
        )}
      </Host>
    );
  }
}
