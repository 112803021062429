x-icon-button
  display: contents
  a,
  button
    border: none
    background: none
    color: currentColor
    font-size: 0
    line-height: 0

  &[color="primary"] a,
  &[color="primary"] button
    color: var(--primary)
    &:hover
      color: var(--primary-hover)
  &[color="dark-50"] a,
  &[color="dark-50"] button
    color: var(--icon-dark-50)
    &:hover
      color: var(--primary)
  &[color="dark-grey"] a,
  &[color="dark-grey"] button
    color: var(--icon-dark-grey)
    &:hover
      color: var(--primary)
  &[color="red"] a,
  &[color="red"] button
    color: var(--icon-red)
    &:hover
      color: var(--button-hover-red)
